import { React } from "react";
import { Link, useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {updateContactMemoryObj} from "../../store/YamatReducer";

export default function SuccessFormSection(props){ 

    const store = useSelector(state => state.reducer)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const addAnotherActivity = () => {
      props.updateFormValues(store.standbyPlaceId, "Place.ID")
      dispatch(updateContactMemoryObj({key: `Place.ID`, value: store.standbyPlaceId}))
      navigate("/")
    }

    // const sendEmail = () => {
    //   window.location.href = "mailto:amutot@ruachtova.org.il"
    // }

    const ButtonMailto = ({ mailto, label }) => {
        return (
            <Link
                to='#'
                onClick={(e) => {
                  window.location.href = mailto;
                  e.preventDefault();
                }}
                className="font-22 mb-5 pb-4"
            >
                {label}
            </Link>
        );
    };
    const autoFocus = (element) => {
        if(!element || element.focused){
            return
        }
        element.focused = true;
        element?.focus();
    }

    return  <>  
                <div className="thankyou_msg rounded-0 p-5 mb-0 d-flex flex-column align-items-center justify-content-center" role="alert">
                    <h4 className="text-color-selected font-xxxl mb-3" tabIndex={0} ref={autoFocus}>תודה על פנייתך! ניצור איתך קשר בהקדם.</h4>
                    <p className="mb-0 font-22 text-color-selected">לשאלות נוספות ניתן ליצור קשר במייל:</p>
                    {/* <div onClick={() => sendEmail()} className="mb-5 pb-4 font-22 primary_color pointer">amutot@ruachtova.org.il</div> */}
                    {/* <a href="mailto:amutot@ruachtova.org.il" className="mb-5 pb-4 font-22">amutot@ruachtova.org.il</a> */}
                    <ButtonMailto label="amutot@ruachtova.org.il" mailto="mailto: amutot@ruachtova.org.il" />
                    <button className="btn btn-primary popup_btn f-bold" onClick={addAnotherActivity}>הוסיפו עוד פעילות</button>
                </div>
            </>
}