import React, { useState } from "react";
import './AlertPopup.css'
import { Modal } from "react-bootstrap";
import CloseCircleIcon from "../../assets/svg/closeCircle";
import AlertIcon from "../../assets/svg/alert";

export default function AlertPopup(props) {

  const [activeCloseBtn, setActiveCloseBtn] = useState(false)

    return  <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={props.modalShow}
                onHide={() => props.setModalShow(false)}
            >
                <div className='f-reg px-5 py-4 mb-3 relative'>
                    <button className='position-absolute close_circle_icon pointer' onClick={() => props.setModalShow(false)} onMouseLeave={() => setActiveCloseBtn(false)} onMouseEnter={() => setActiveCloseBtn(true)}><CloseCircleIcon active={activeCloseBtn} /></button>
                    <div className="text-center mt-4 mb-4"><AlertIcon/></div>
                    <h3 className='text-center primary_color f-bold mt-3 mb-4'>{props.textLine1} <br/> {props.textLine2} </h3>
                   
                    {props.tryAgainFunc && <div className='d-flex justify-content-center mt-4 pt-3'>
                        <button onClick={() => {props.tryAgainFunc(); props.setModalShow(false)}} className='f-bold btn btn-primary text-center popup_btn'>נסיון נוסף</button>
                    </div>}
                </div>
            </Modal> 
}