import React, { useState } from "react";
import { fieldTypeDic, getValue } from "../../utils/externalFunc";
import { useSelector } from "react-redux";

export default function FormInput(props) {

  const store = useSelector(state => state.reducer)
  const [showTooltip, setShowTooltip] = useState(false)
  
    return    <>
              <label htmlFor={props.keyName} className={`form-label w-100 ${props.keyName}`}>
                  <div className="d-flex justify-content-between">
                    <span>{props.item.IsRequired ? "*" : ""}{props.item.Title}</span>
                    {/* Only on גינון וצביעה */}
                    {store.formVals.ProjectDetails.FieldId === 5 && props.formFieldDetails?.object_path === "ProjectDetails.VolunteersNum" && 
                     <div className="text-decoration-underline primary_color pointer position-relative"  
                      onMouseLeave={() => setShowTooltip(false)} onMouseEnter={() => setShowTooltip(true)}>הסבר
                      {showTooltip && <div className="tooltip_line position-absolute bg-body p-3">לחישוב כמות המתנדבים הדרושה לפרויקט, יש לחלק את השטח הכולל ב-10. לדוגמא 150 מ״ר = 15 מתנדבים</div>}
                    </div>} 
                  </div>
                  <div className="input-group input-group-sm mb-3"> 
                      <input 
                        type={fieldTypeDic[props.item.FormFieldType]} 
                        value={getValue(props.store, props.formFieldDetails?.object_path)} 
                        onChange={(e) => props.updateFormValues(fieldTypeDic[props.item.FormFieldType] === 'number' ? e.target.value ? Number(e.target.value) : e.target.value : e.target.value, props.formFieldDetails.object_path)} 
                        className={`form-control ${(props.formErrors[props.keyName] || props.formErrors[props.keyName] === "") && !props.disabled ? "is-invalid" : ""}`} 
                        id={props.keyName} 
                        required={props.item.IsRequired}     
                        disabled={props.disabled}
                        min={fieldTypeDic[props.item.FormFieldType] === 'number' ? "0" : ""}
                        onWheel={(e) => e.target.blur()}
                        autoFocus={props.autoFocus ? true : false}
                        />                      
                      <div className="invalid-feedback">{ props.errorsObjReady[props.keyName] || `יש למלא ${props.item.Title}` }</div>
                  </div>
              </label></>
}