import { React, useEffect, useRef, useState } from "react";
// import { addProject, apiFormValidation, editProject, getMunicForm, getProjectInfo } from "../../utils/apiUtils";
import './ActionForm.css';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import ContactSection from "./ContactSection";
import { useDispatch, useSelector } from "react-redux";
import SuccessFormSection from "./SuccessFormSection";
import { useNavigate } from "react-router-dom";
import AlertPopup from "../../components/AlertPopup/AlertPopup";
import { extractKeyName, stepsDic } from "../../utils/externalFunc";
import ActivitySection from "./ActivitySection";
import {
    clearFormValsForAnotherActivity,
    setFormActiveStep,
    setFormVals,
    setStandbyPlaceId,
    updateContactMemoryObj
} from "../../store/YamatReducer";
import { apiFormValidation, getOrganizationForm, sendFromFinal } from "../../utils/apiUtils";



export default function ActionForm(props){
    
    const store = useSelector(state => state.reducer)
    const [formPageData, setFormPageData] = useState(false)
    const [formLoader, setFormLoader] = useState(false)
    const [steps, setSteps] = useState(["מה תרצו לעשות?", "פרטי איש קשר"])
    const [activeStep, setActiveStep] = useState(store.activeStep)
    const [sectionsArray, setSectionsArray] = useState(["ActivitySection_form", "ContactSection_form"])
    const [formValidation, setFormValidation] = useState({ActivitySection_form: "start", ContactSection_form: "start"})
    const [errorsObjReady, setErrorsObjReady] = useState({})
    const [errorModalShow, setErrorModalShow] = useState(false)
    const [errorModalText, setErrorModalText] = useState(false)
    //const [loadProjectAlertModalShow, setLoadProjectAlertModalShow] = useState(false)
    const [formValsLocal, setFormValsLocal] = useState(store.formVals)
    //const [activityFormSection, setActivityFormSection] = useState(false)
    const [successMsg, setSuccessMsg] = useState("")
    const [formLoad, setFormLoad] = useState(false)
    const [getFormloader, setGetFormloader] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [firstStepTitle, setFirstStepTitle] = useState("")
    const switches = ["IsWallPainting", "IsRenewalPainting", "IsGardening"]

    const getForm = async () => {
        setGetFormloader(true)
        getOrganizationForm(store.formVals.ProjectDetails.FieldId).then(res => {
            setFormPageData(res.data)
            setFirstStepTitle(res.data.Title)
        })
        .catch(error => {
            console.log(error)
        }).finally(x => setGetFormloader(false))
    }

    const handleNext = () => {
        activeStep < stepsDic.SuccessFormSection && checkStep()
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    let finalMount = useRef(true);
    useEffect(() => {
        if (finalMount.current) {
            finalMount.current = false;
            if(store.formVals.ProjectDetails.FieldId !== 0){
                getForm()
                setFormLoad(true)
            }else{
                navigate("/")
            }
        }
    }, [])

    useEffect(() => {
        formLoad && getForm()
      }, [store.formVals.ProjectDetails.FieldId])

    const sendForm = () => {
        // if validation ok, send form or step up
        if(activeStep + 1 === stepsDic.SuccessFormSection){
            setFormLoader(true)
            let jsonToSend = store.formVals
            switches.map(item => {   
                if(!store.formVals.ProjectDetails.ProjectLogistics[item]){
                    store.switchArrayKeys[item].map(key => {
                        let value = (key.includes("GardeningIds") || key.includes("FacilitiesColours") || key.includes("FenceColours")) ? [] : 0
                        let pathArray = key.split(".")
                        jsonToSend = {...jsonToSend, [pathArray[0]]: { ...jsonToSend[pathArray[0]], [pathArray[1]]: {...jsonToSend[pathArray[0]][pathArray[1]], [pathArray[2]]: value}}}
                        updateFormValues(value, key)
                    })
                }
            })
            //console.log(jsonToSend)
            sendFromFinal(jsonToSend).then(res => {
                if(res.data.success){
                    setSuccessMsg(res.data.success_message)
                    res.data?.guid_id && dispatch(setStandbyPlaceId(res.data.guid_id))
                    setActiveStep(stepsDic.SuccessFormSection)
                    dispatch(clearFormValsForAnotherActivity())
                }else{
                    setErrorModalText("שגיאה בשמירת הטופס")
                    setErrorModalShow(true)
                }
            }).catch(error => {
                setErrorModalText(error?.response?.data?.Message)
                setErrorModalShow(true)
            }).finally(() => setFormLoader(false))
        }else{
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
        }
    }

    const checkStep = () => {
        apiFormValidation(store.formVals).then(res => {
             return manageErrors(res.data.errors) && 
                    (activeStep + 1) < 3 && 
                    sendForm()
        }).catch(error => {
            console.error(error)
        })
    }

    const bootstrapFormValidation = () => {
        let form = document.getElementById(sectionsArray[activeStep])
        setFormValidation({...formValidation, [sectionsArray[activeStep]]: form.checkValidity()})
        return form.checkValidity()
    }

    const manageErrors = (errArray) => {
        let errors = {}
        if(errArray){
            if(errArray.length > 0){
                errArray.map(item => {
                    if(store.formFieldsForValidation.includes(item.Key)){
                        let key = item.Key.split(".")
                        key = key.length > 0 ? key[key.length -1] : key
                        errors[key] = item.Value[0]
                    }
                })
            }
        }
        switches.map(item => {   
            if(!store.formVals.ProjectDetails.ProjectLogistics[item]){
                store.switchArrayKeys[item].map(key => {
                    let keyName = extractKeyName(key)
                    delete errors[keyName]
                })
            }
        })
        //console.log(errors)
        setErrorsObjReady(errors)
        return Object.keys(errors).length === 0
    }

    useEffect(() => {
        dispatch(setFormActiveStep(activeStep))
    }, [activeStep])

    useEffect(() => {
        dispatch(setFormVals({...formValsLocal}))
    }, [formValsLocal])

    const updateFormValues = (value, path) => {
        let pathArray = path.split(".")
        let key = pathArray[pathArray.length - 1]
        setErrorsObjReady({...errorsObjReady, [key]: null})
        if(typeof(pathArray) !== "undefined" && pathArray.length > 0){
            if(pathArray.length === 2){
                let newFormVals = {...store.formVals, [pathArray[0]]: { ...store.formVals[pathArray[0]], [pathArray[1]]: value}}
                dispatch(setFormVals(newFormVals))
                if(key === "Email" || key === "Position" || key === "Phone" || key === "FirstName" || key === "LastName" ){
                    dispatch(updateContactMemoryObj({key: `Contact.${key}`, value: value}))
                }
                if(key === "Branch" || key === "OrganizationName"){
                    dispatch(updateContactMemoryObj({key: `Place.${key}`, value: value}))
                }
            }else if(pathArray.length === 3){
                dispatch(setFormVals({...store.formVals, [pathArray[0]]: { ...store.formVals[pathArray[0]], [pathArray[1]]: {...store.formVals[pathArray[0]][pathArray[1]], [pathArray[2]]: value}}}))
            }
        }
    }

    return  <div className="container-fluid ActionForm align-items-center d-flex flex-column min-height-100">
                <h1 className="text-center primary_color f-bold mt-4 mb-2 mt-md-4 mb-md-4 pt-2">{props.mainPageDataObj.Title}</h1>
                
                    <div className="mx-3 mt-2 py-5 bg-body w-100 d-flex flex-column align-items-center">
                        {activeStep < 2 && <div className="stepper_con" arearia-hidden="true">
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps?.map((label, index) => (
                                <Step key={index}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                                ))}
                            </Stepper>
                        </div>}
                        {activeStep == 0 && <span className={'sr-only'}>שלב ראשון מתוך 2 מה תרצו לעשות</span>}
                        {activeStep == 1 && <span className={'sr-only'}>שלב שני מתוך 2 מה תרצו לעשות</span>}

                        {activeStep === stepsDic.ActivitySection_form && formPageData && 
                        <ActivitySection
                            updateFormValsFromMemoryObject={props.updateFormValsFromMemoryObject}
                            errorsObjReady={errorsObjReady} 
                            validation={formValidation[sectionsArray[activeStep]]} 
                            updateFormValues={updateFormValues} 
                            stepInfo={formPageData.FormSteps} 
                            activityFormSection={props.topSectionForm}
                            firstStepTitle={firstStepTitle}
                            getFormloader={getFormloader}
                        />}
                        {activeStep === stepsDic.ContactSection_form && formPageData && props.topSectionForm && 
                        <ContactSection 
                            errorsObjReady={errorsObjReady} 
                            validation={formValidation[sectionsArray[activeStep]]} 
                            updateFormValues={updateFormValues} 
                            stepInfo={props.topSectionForm}
                        />}
                        {activeStep === stepsDic.SuccessFormSection && formPageData && <SuccessFormSection successMsg={successMsg} updateFormValues={updateFormValues}/>}

                    </div>
                        
                        {activeStep < stepsDic.SuccessFormSection && <div className="d-flex justify-content-center py-5">
                            <button color="inherit" disabled={activeStep === stepsDic.ActivitySection_form} onClick={handleBack} className="btn-primary btn ms-0 me-1 f-bold">הקודם</button>
                            <button onClick={handleNext} className="btn-primary btn f-bold">
                                {/* last stage -> PartnerSection */}
                                {activeStep === stepsDic.PartnerSection_form ? 'נצא לדרך' : 'הבא'}
                                {formLoader && <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>}
                            </button>
                            <AlertPopup textLine1={errorModalText} textLine2="" setModalShow={setErrorModalShow} modalShow={errorModalShow} tryAgainFunc={handleNext}/>
                            {/* <AlertPopup textLine1={`ארעה תקלה בטעינת הפרויקט`} textLine2={`שורה שניה לבחירה`} setModalShow={setLoadProjectAlertModalShow} modalShow={loadProjectAlertModalShow} tryAgainFunc={false}/> */}
                        </div>} 
                            
            </div>
}