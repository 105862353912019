import React from "react";
import { getValue } from "../../utils/externalFunc";

export default function FormTimeSelect(props) {

    return  <label htmlFor={props.keyName} className={`form-label w-100 mb-2 w-100`}>{props.item.IsRequired ? "*" : ""}{props.item.Title}
                <div className="input-group input-group-sm mb-3"> 
                    <select className={`form-select ${(props.formErrors[props.keyName] || props.formErrors[props.keyName] === "") && !props.disabled ? "is-invalid" : ""}`} value={getValue(props.store, props.formFieldDetails?.object_path)} onChange={(e) => props.updateFormValues(e.target.value, props.formFieldDetails.object_path)} id={props.keyName} required={props.item.IsRequired} aria-label={props.item.Title}>
                        <option value="">בחרו שעה</option>
                        {
                            props.hoursArray?.map((subItem, index) => {
                                return <option key={'hours_' + index} value={subItem.hourValue}>{subItem.hour}</option>
                            })
                        }
                    </select>
                    <div className="invalid-feedback">{props.errorsObjReady[props.keyName] || `יש למלא ${props.item.Title}`}</div>
                </div>
            </label>
}