import { React, useEffect, useState } from "react";
import './ActionForm.css';
import {
    CustomSwitchBox,
    checkArray,
    extractKeyName,
    fieldTypeDic,
    getJsonStringDetails,
    getValue,
    handleFormData,
    isJsonString,
    sortArray, updateActivity
} from "../../utils/externalFunc";
import { useDispatch, useSelector } from "react-redux";
import { setFormFieldsForValidation, setSwitchArrayKeys } from "../../store/YamatReducer";
import FormInput from "../../components/FormParts/FormInput";
import FormTextarea from "../../components/FormParts/FormTextarea";
import FormSingleSelect from "../../components/FormParts/FormSingleSelect";
import FormTimeSelect from "../../components/FormParts/FormTimeSelect";
import FormMultipleSelect from "../../components/FormParts/FormMultipleSelect";
import { getCitiesArray } from "../../utils/apiUtils";
import { useRef } from "react";
import FormAutocomplete from "../../components/FormParts/FormAutocomplete";
import NoticePopup from "../../components/NoticePopup/NoticePopup";
import { ThemeProvider, createTheme } from "@mui/material";
import createCache from '@emotion/cache';
import { CacheProvider } from "@emotion/react";
import { prefixer } from "stylis";
import stylisRTLPlugin from "stylis-plugin-rtl";

export default function ActivitySection(props){

    const store = useSelector(state => state.reducer)
    const dispatch = useDispatch()
    const [selectedActivity, setSelectedActivity] = useState({})
    const [accordionTabs, setAccordionTabs] = useState({2: false, 3: false, 4: false})
    const [underSelectText, setUnderSelectText] = useState(false)
    const [hoursArray, setHoursArray] = useState([])
    const [formErrors, setFormErrors] = useState({})
    const [mainArray, setMainArray] = useState()
    const [topSection, setTopSection] = useState()
    const [citiesArray, setCitiesArray] = useState([])
    const [modalShow, setModalShow] = useState(false)

    const theme = createTheme({
        direction: 'rtl'
    })
    // Create rtl cache
    const cacheRtl = createCache({
        key: 'muirtl',
        stylisPlugins: [prefixer, stylisRTLPlugin],
    })

    const autoFocus = (element) => {
        if(!element || element.focused){
            return
        }
        element.focused = true;
        element?.focus();
    }

    let finalMount = useRef(true);
    useEffect(() => {
        if (finalMount.current) {
            finalMount.current = false;
            buildHoursArray()
            getCities()
        }
    }, [])

    const getCities = () => {
        getCitiesArray().then(res => {
            setCitiesArray(res.data)
        }).catch(error => console.log(error))
    }

    const buildHoursArray = () => {
        let hours = [];
        for(let i = 0; i < 24; i++){
           let fullHour = i < 10 ? '0' + i + ':' + '00' : i + ':' + '00'
           let halfHour = i < 10 ? '0' + i + ':' + '30' : i + ':' + '30'
           let fullHourValue = fullHour + ':' + '00'
           let halfHourValue = halfHour + ':' + '00'
            hours.push({hour: fullHour, hourValue: fullHourValue})
            hours.push({hour: halfHour, hourValue: halfHourValue})
        }
        setHoursArray(hours)
    }

    const updateGlobalActivity = () => {
        // props.updateFormValues(selectedActivity.value, selectedActivity.path)
        updateActivity(dispatch, selectedActivity.value)
        setModalShow(false)
    }

    const createFormFieldsArrayForValidation = (arr) => {
     //dispatch(setFormFieldsForValidation(arr))
    console.log(arr)
    }

    useEffect(() => {
        let topSectionArray = checkArray(props.activityFormSection.FormSteps)
        topSectionArray && setTopSection(topSectionArray[0].FormFields)
    }, [props.activityFormSection])

    useEffect(() => {

        let formArray = checkArray(props.stepInfo)
        if(formArray){
            let formData = handleFormData(formArray[0].FormFields, props.firstStepTitle)
            let mainArrayReady = formData.final
            let formStepDetails = getJsonStringDetails(formArray[0].FormStepDetails)
            setUnderSelectText(formStepDetails.subtitle ? formStepDetails.subtitle : false)
            setMainArray(mainArrayReady)
            formData.validationArray && dispatch(setFormFieldsForValidation(formData.validationArray))
            formData.switchArrayKeys && dispatch(setSwitchArrayKeys(formData.switchArrayKeys))
        }
    }, [props.stepInfo])

    useEffect(() => {
        setFormErrors(props.errorsObjReady)
    }, [props.errorsObjReady])

    // useEffect(() => {
    //     let arr = sortArray(props.stepInfo[0].FormFields)
    //     setMainArray(arr)
    // }, [props.stepInfo[0].FormFields])

    /** on change trigger accordion tabs */

    useEffect(() => {
        setAccordionTabs({...accordionTabs, 2: store.formVals.ProjectDetails.ProjectLogistics.IsGardening})
    }, [store.formVals.ProjectDetails.ProjectLogistics.IsGardening])

    useEffect(() => {
        setAccordionTabs({...accordionTabs, 3: store.formVals.ProjectDetails.ProjectLogistics.IsWallPainting})
    }, [store.formVals.ProjectDetails.ProjectLogistics.IsWallPainting])

    useEffect(() => {
        setAccordionTabs({...accordionTabs, 4: store.formVals.ProjectDetails.ProjectLogistics.IsRenewalPainting})
    }, [store.formVals.ProjectDetails.ProjectLogistics.IsRenewalPainting])

    return  (  
            <form action="" id="ActivitySection_form" className={`px-3 px-md-0 w-100 ActivitySection_form needs-validation ${props.validation === "start" ? "" : "was-validated"}`} noValidate> {/** was-validated */}

                <div className="row mt-4">

                    <div className="d-flex flex-column align-items-center mt-5 mb-3">
                        <div className="select_con input-group input-group-sm mb-3"> 
                            {
                                topSection?.map((item) => {                                   
                                    let formFieldDetails = item.FormFieldDetails && isJsonString(item.FormFieldDetails) ? JSON.parse(item.FormFieldDetails) : false
                                    if(formFieldDetails){
                                        let keyName = extractKeyName(formFieldDetails.object_path)                             
                                        if(fieldTypeDic[item.FormFieldType] === 'SelectSingle'){
                                            return  <div className="w-100" key={keyName}>
                                                        <label key={keyName} htmlFor={keyName} className="form-label f-bold mb-2 w-100 text-center"><span ref={autoFocus} className="font-xl" tabIndex={'0'}>{item.Title}</span>
                                                            <div className="input-group f-reg input-group-sm"> 
                                                                <select className={`form-select ${(formErrors[keyName] || formErrors[keyName] === "") && !props.disabled ? "is-invalid" : ""}`} value={getValue(store, formFieldDetails?.object_path)} 
                                                                onChange={(e) => {
                                                                    if(e.target.value){
                                                                        setSelectedActivity({value: Number(e.target.value), path: formFieldDetails?.object_path})
                                                                        setModalShow(true)
                                                                    }else{
                                                                        //props.updateFormValues(Number(e.target.value), formFieldDetails?.object_path)
                                                                        updateActivity(dispatch, Number(e.target.value))
                                                                    }
                                                                }} 
                                                                id={keyName} required={true} 
                                                                aria-label={item.Title}>
                                                                    <option value="">בחרו מהרשימה</option>
                                                                    {
                                                                    formFieldDetails.select_values ? formFieldDetails.select_values.map((subItem) => {
                                                                            return <option key={subItem.id} value={subItem.id}>{subItem.title}</option>
                                                                        }) : null
                                                                    }
                                                                </select>
                                                                <div className="invalid-feedback text-start">{props.errorsObjReady[keyName] || `יש למלא ${item.Title}`}</div>
                                                            </div>
                                                        </label>
                                                        {/* <p className="font-s"><span className="fw-bold">שימו לב!</span> מספר המתנדבים הנדרש יחושב לפי מתנדב על כל 10 מטר של צביעה, ולפחות 15 מתנדבים לפרויקט</p> */}
                                                        <p className="font-s" tabIndex={'0'}>{underSelectText}</p>
                                                    </div>
                                        }
                                    }
                                })
                            }
                        </div>
                    </div>

                    <div className="accordion accordion-flush" id="main_accordion">   
                    {
                        (!props.getFormloader && citiesArray) ? mainArray?.map((accordionItem) => {                      
                            //console.log(accordionItem)
                            var accordionSwitchValue = accordionItem.object_path ? getValue(store, accordionItem.object_path) : true
                            return  (<fieldset><div key={accordionItem.id} className="accordion-item">
                                        <legend>
                                            <h2 tabIndex={0} className="accordion-header border-top" id={`heading_${accordionItem.id}`}>
                                                <div className="position-relative">
                                                    <button className={`accordion-button px-0 ${accordionItem.id !== 1 && accordionTabs[accordionItem.id] === false ? "collapsed" : ""}`} type="button" data-bs-toggle="collapse" data-bs-target={`#accordion_${accordionItem.id}`} aria-expanded={accordionItem.id !== 1 ? "false" : "true"} aria-controls="flush-collapseOne">
                                                        {accordionItem.StepTitle}
                                                    </button>

                                                    {accordionItem.id !== 1 && <div className="accordion_checkbox position-absolute accordion_switch">
                                                        <CacheProvider value={cacheRtl}>
                                                            <ThemeProvider theme={theme}>
                                                                <CustomSwitchBox
                                                                    aria-label={accordionItem.StepTitle}
                                                                    onChange={(e) => {
                                                                        props.updateFormValues(e.target.checked, accordionItem.object_path)
                                                                    }}
                                                                    checked={accordionSwitchValue}
                                                                />
                                                            </ThemeProvider>
                                                            </CacheProvider>
                                                    </div>}
                                                </div>
                                            </h2>
                                        </legend>
                                        <div id={`accordion_${accordionItem.id}`} className={`accordion-collapse collapse ${accordionItem.id === 1 || accordionTabs[accordionItem.id] === true ? "show" : ""}`} aria-labelledby={`heading_${accordionItem.id}`}>
                                            <div className="accordion-body px-0 overflow-hidden">
                                                <span className={'note'}>* כוכבית מסמנת שדה חובה</span>
                                                {accordionItem.StepSubTitle && <div className="primary_color mb-4 pb-2 font-s">{accordionItem.StepSubTitle}</div>}
                                                {
                                                    accordionItem.FormFields?.map((row, index) => {
                                                        return  <div key={index} className={`gx-md-5 row ${index === accordionItem.FormFields.length - 1 ? "" : "border-1 border-bottom"} mt-3`}>
                                                                    { row?.map((col, ind) => {
                                                                        let rowLength = row.length
                                                                        return  <div key={ind} className={`col-12 ${rowLength === 2 ? "col-md-6" : "col-md-4"} ${accordionItem.id !== 1 ? "d-flex flex-column justify-content-end" : ""}`}> {/** ${accordionItem.id === 1 ? "row": ""} */}
                                                                                    {col.col?.map(item => {
                                                                                        let formFieldDetails = item.FormFieldDetails && isJsonString(item.FormFieldDetails) ? JSON.parse(item.FormFieldDetails) : false
                                                                                        if(formFieldDetails){
                                                                                            let keyName = extractKeyName(formFieldDetails.object_path)
                                                                                            return (
                                                                                                <div className={`${fieldTypeDic[item.FormFieldType] === 'Time' ? "col-time" : ""}`} key={item.FormFieldID}>
                                                                                                    {formFieldDetails.section_title && <p className="text-color-regular f-bold mb-0" tabIndex={0}>{formFieldDetails.section_title}</p>}
                                                                                                    {formFieldDetails.section_subtitle && <p className="primary_color mb-0 font-s">{formFieldDetails.section_subtitle}</p>}
                                                                                                    {fieldTypeDic[item.FormFieldType] === "SelectMultiple" &&
                                                                                                        <FormMultipleSelect disabled={!accordionSwitchValue} keyName={keyName} item={item} store={store} updateFormValues={props.updateFormValues} errorsObjReady={props.errorsObjReady} formErrors={formErrors} formFieldDetails={formFieldDetails}/>}
                                                                                                    {(fieldTypeDic[item.FormFieldType] === 'text' || fieldTypeDic[item.FormFieldType] === 'tel' ||
                                                                                                            fieldTypeDic[item.FormFieldType] === 'email' || fieldTypeDic[item.FormFieldType] === 'number') &&
                                                                                                        <FormInput rowLength={rowLength} disabled={!accordionSwitchValue} keyName={keyName} item={item} store={store} updateFormValues={props.updateFormValues} errorsObjReady={props.errorsObjReady} formErrors={formErrors} formFieldDetails={formFieldDetails}/>}
                                                                                                    {(fieldTypeDic[item.FormFieldType] === 'textarea') &&
                                                                                                        <FormTextarea disabled={!accordionSwitchValue} keyName={keyName} item={item} store={store} updateFormValues={props.updateFormValues} errorsObjReady={props.errorsObjReady} formErrors={formErrors} formFieldDetails={formFieldDetails}/>}
                                                                                                    {fieldTypeDic[item.FormFieldType] === 'SelectSingle' &&
                                                                                                        <FormSingleSelect rowLength={rowLength} disabled={!accordionSwitchValue} keyName={keyName} item={item} store={store} updateFormValues={props.updateFormValues} errorsObjReady={props.errorsObjReady} formErrors={formErrors} formFieldDetails={formFieldDetails}/>}
                                                                                                    {fieldTypeDic[item.FormFieldType] === 'Time' &&
                                                                                                        <FormTimeSelect keyName={keyName} item={item} store={store} updateFormValues={props.updateFormValues} errorsObjReady={props.errorsObjReady} formErrors={formErrors} hoursArray={hoursArray} formFieldDetails={formFieldDetails}/>}
                                                                                                    {fieldTypeDic[item.FormFieldType] === 'SelectAutoComplete' &&
                                                                                                        <FormAutocomplete citiesArray={citiesArray} keyName={keyName} item={item} store={store} updateFormValues={props.updateFormValues} errorsObjReady={props.errorsObjReady} formErrors={formErrors} hoursArray={hoursArray} formFieldDetails={formFieldDetails} accordionItem={accordionItem}/>}
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    })}
                                                                                </div>
                                                                    })}
                                                                </div>
                                                    })

                                                }
                                            </div>
                                        </div>
                                    </div></fieldset>)
                        }) : 
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="spinner-border" style={{color: props.leadColor}} role="status"></div>
                        </div>
                        
                    }
                    </div>
                </div>
                <NoticePopup setModalShow={setModalShow} modalShow={modalShow} updateGlobalActivity={updateGlobalActivity} selectedActivity={selectedActivity.value}/>
            </form>
            )           
}
